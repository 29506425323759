










import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class AgreementPage extends Vue {
  get breadcrumbs(): string[] {
    let pathArray: string[] = this.$route.path.split("/");
    pathArray.shift();
    let breadcrumbs: string[] = pathArray.reduce((breadcrumbArray, path, idx) => {
      breadcrumbArray.push({
        path: path,
        href: breadcrumbArray[idx - 1]
          ? "/#/" + breadcrumbArray[idx - 1].path + "/" + path
          : "/#/" + path,
        text: this.$route?.matched[idx]?.meta?.breadcrumb || path
      });
      return breadcrumbArray;
    }, []);
    return breadcrumbs;
  }
}
